@font-face {
    font-family: 'AGLettericaCondensedLight-Roman';
    src: url('fonts/AGLettericaCondensedLight-Roman.eot');
    src: url('fonts/AGLettericaCondensedLight-Roman.eot?#iefix') format('embedded-opentype'),
         url('fonts/AGLettericaCondensedLight-Roman.woff') format('woff'),
         url('fonts/AGLettericaCondensedLight-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AGReverenceC';
    src: url('fonts/AGReverenceC.eot');
    src: url('fonts/AGReverenceC.eot?#iefix') format('embedded-opentype'),
        url('fonts/AGReverenceC.woff') format('woff'),
        url('fonts/AGReverenceC.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.top_menu_list a {
    text-decoration: none !important;
}




html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    list-style: none;
}


.container_main.App .marquiz__button_fixed {
    height: 36px;
    line-height: 36px;
    font-family: 'AGLettericaCondensedLight-Roman'!important;
    font-weight: 100;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0px;
}
.container_main.App .marquiz__button_fixed .icon-quiz {
    height: 22px;
    width: 22px;
    padding: 4px 2px 4px 2px;
}
.container_main.App{
    font-family: 'AGLettericaCondensedLight-Roman';
    text-align: left;
    min-width: 320px;
    overflow: hidden;
    color: #3b4454;
}
#root{
    position: relative;
    z-index: 1;
}
.clr{
    clear: both;
}
.Mui-error ~ .MuiInput-underline:before {
    border-bottom: 2px solid rgb(255, 0, 0)!important;
    transition: .3s;
}
.Mui-error{
    color: rgba(255, 0, 0)!important;
    font-weight: bold!important;
    transition: .3s;
}


/* menu */
.menu{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.menu_btn{
    display: none;
}
.menu .cw_main {
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 25px;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
logo{
    display: block;
    position: relative;
    width: 172px;
}
logo img{
    display: block;
    width: 100%;
}

.top_menu_list{
    display: inline-block;
}
.top_menu_list a{
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    color: #384251;
    cursor: pointer;
    padding: 0 6px;
    transition: 0.3s;
}
.top_menu_list a+a{
    margin-left: 50px;
}

.top_menu_list a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -12px;
    background-image: linear-gradient( 89deg, rgb(75,223,244) 0%, rgb(91,194,238) 79%);
    opacity: 0;
    transition: 0.3s;
}
.top_menu_list a:hover:after {
    opacity: 1;
    bottom: -3px;
}
.top_menu_list a:active{
    color: #7bd8ff;
}
.top_menu_list a span{
    display: block;
    position: relative;
    z-index: 2;
}

.top_menu_list a span:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #384251;
    left: 0;
    bottom: -1px;
    opacity: 1;
    transition: 0.3s;
}
.top_menu_list a span:hover:after{
    opacity: 0;
    bottom: -5px;
}
.ipoteka__info--wrap {
    position: relative;
}
.ipoteka__info {
    background: #8de5f7;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.ipoteka__info--title {
    font-family: 'AGReverenceC';
    font-size: 46px;
    color: #ffffff;
}
.ipoteka__info--subtitle {
    color: #ffffff;
    font-family: 'AGReverenceC';
    font-size: 18px;
    padding-bottom: 5px;
}
.top_menu_list a:active:after{
    opacity: 0;
}
.top_menu_list a:active span:after{
    opacity: 0;
}

.menu_btn__sales {

}
.menu_right{
    display: inline-block;
}
.menu_right a{
    display: block;
    position: relative;
    font-size: 24px;
    line-height: 1;
    color: #8ee2f6;
    text-decoration: none;
    transition: .7s ease-in-out;
}
.menu_right a:hover{
    color: #137891;
}


.menu_right a l{
    display: block;
    position: absolute;
    left: -50px;
    top: 50%;
    transform-origin: center center;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #8ee2f6 url(../images/menu_phone.svg)no-repeat center center;
    background-size: 20px 20px;
}
.menu_right a l:after{
    display: block;
    position: absolute;
    content: '';
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #8ee2f6;
    transition: .6s ease-in-out;
}
.menu_right a l:before{
    display: block;
    position: absolute;
    content: '';
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #8ee2f6;
    transition: .6s ease-in-out;
}
.menu_right a:hover l:before {
    animation: 2s ease-in-out infinite pulse;
}
.menu_right a:hover l:after {
    animation: 2s ease-in-out .5s infinite pulse;
}
/* menu */

/* header */
.header{
    display: block;
    position: relative;
    background: url(../images/header_bg.jpg)no-repeat center top;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    box-sizing: border-box;
}
.header .cw_main{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100vh;
}
.hd_inner{
    display: block;
    width: 570px;
    text-align: center;
    box-sizing:border-box;
    margin: 0 auto;
    padding: 42px 52px 28px 52px;
    background: #8de5f7;
}
h1{
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 85px;
    line-height: 1;
    font-family: 'AGReverenceC';
    font-weight: normal;
}
h1 span{
    display: block;
    font-size: 28px;
    line-height: 1;
}
.hd_info {
    display: block;
    font-size: 26px;
    line-height: 1;
    margin-top: 8px;
    color: #49a6ce;
}
.header_dec_bg{
    bottom: -40px;
    left: 0;
}
.dec_bg{
    display: block;
    position: absolute;
    width: 100%;
    pointer-events: none;
}
.cw_main{
    display: block;
    margin: 0 auto;
    max-width: 1374px;
}
/* header */



/* advantages */
.advantages{
    padding-top: 96px;
    padding-bottom: 82px;
}
.tm{
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 72px;
    line-height: 1;
    color: #3b4251;
    font-family: 'AGReverenceC';
}
.tm span{
    display: inline-block;
    position: relative;
}
.advant_list {
    padding-top: 96px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}
.advant_list .adv_el{
    display: block;
    width: 370px;
    transition: 0.4s;
}
.adv_el img{
    display: block;
    max-width: 100%;
}
.advant_list .adv_el:nth-child(2){
    width: 430px;
}
.adv_img_top {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.adv_img_top span{
    margin-left: 34px;
}
.adv_el span{
    display: block;
    position: relative;
    font-size: 30px;
    line-height: 1;
    font-family: 'AGReverenceC';
    color: #1f2229;
    transition: 0.4s;
}
.adv_el span:before {
    display: block;
    position: absolute;
    content: '';
    width: 42px;
    height: 42px;
    background: #8fe5f7;
    border-radius: 50%;
    left: -19px;
    top: -8px;
    transition: 0.4s;
}
.adv_el span l{
    display: block;
    position: relative;
    z-index: 2;
}
.advant_list .adv_el:hover .adv_img_top span{
    margin-left: 40px;
}
.advant_list .adv_el:hover span:before{
    width: 48px;
    height: 48px;
    left: -10px;
}

.adv_img_bottom{
    display: block;
    margin-top: 72px;
    transition: 0.4s;
}
.adv_img_bottom span{
    margin-top: 40px;
    margin-left: 20px;
}
.advant_list .adv_el:hover .adv_img_bottom{
    margin-top: 60px;
}
.advant_list .adv_el:hover .adv_img_bottom span{
    margin-top: 30px;
}
.adv_el > span{
    margin-top: 40px;
    margin-left: 20px;
}
/* advantages */



/* loc */
.loc{
    display: block;
    position: relative;
    background: url(../images/loc_bg.jpg)no-repeat center left;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 450px;
    padding-bottom: 384px;
    overflow: hidden;
}
.top_btn{
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 101px;
    height: 61vh;
    background: linear-gradient(0deg, rgba(148,215,244,1) 38%, rgba(255,255,255,0) 100%);
    opacity: .5;
    transition: 1s;
    cursor: pointer;
    z-index: 105;
}
.top_btn:hover{
    opacity: 1;
}
.top_btn img{
    display: block;
    position: absolute;
    width: 22px;
    height: 63px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 56px;
}
.loc_dec_bg{
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: -3px;
    z-index: 3;
}
.elipses {
    display: block;
    position: absolute;
    width: 1616px;
    /* width: 84vw; */
    left: -19%;
    top: -43%;
}
.elipses > img{
    max-width: 100%;
}
.loc .tm{
    position: relative;
    z-index: 3;
    text-align: left;
    pointer-events: none;
}
.loc .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateY(-49%);
    top: 50%;
    left: -80px;
}
.loc_el {
    display: flex;
    position: absolute;
    z-index: 1;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.loc_img {
    display: flex;
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #94d7f4;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #00f6ff, #3d86a5 95%);
}
.loc_img:after{
    display: block;
    position: absolute;
    content: '';
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #8ee2f6;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.loc_img:before{
    display: block;
    position: absolute;
    content: '';
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #00f6ff;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.loc_el:hover .loc_img:before{
    animation: 2s ease-in-out 2 pulse;
}
.loc_el:hover .loc_img:after{
    animation: 2s ease-in-out .5s 2 pulse;
}
.loc_img img{
    max-width: 36px;
    max-height: 36px;
}
.loc_el span{
    display: block;
    font-size: 30px;
    line-height: 1;
    color: #3b4251;
    font-family: 'AGReverenceC';
    margin-left: 16px;
}
.loc_el1 {
    left: 85.5%;
    top: 36%;
}
.loc_el2 {
    left: 67.5%;
    top: 45%;
}
.loc_el3 {
    left: 97.5%;
    top: 51%;
}
.loc_el4 {
    left: 73.5%;
    top: 62%;
}
.loc_el5 {
    left: 78.5%;
    top: 72%;
}
/* loc */


/* plan_price */
.plan_price{
    padding-top: 226px;
    background: #fff;
    padding-bottom: 30px;
}

.plan_price .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateX(-50%);
    top: -42px;
    left: 50%;
}
.pl_wrap{
    display: block;
    margin: 0 auto;
    margin-right: 0;
    /* width: calc(50vw - 692px + 1374px ); */
    box-sizing: border-box;
    padding-left: 22px;
    margin-top: 86px;
}
.plan_nav{
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.plan_nav > span{
    display: block;
    font-family: 'AGReverenceC';
    color: #c9cacd;
    line-height: 1;
    font-size: 22px;
    margin-bottom: 18px;
}
.plan_nav a{
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #3b4251;
    padding: 0 8px;
    font-size: 18px;
    line-height: 1;
}
.plan_nav a+a{
    margin-left: 26px;
}

.plan_nav a span{
    display: block;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.plan_nav a span:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #384251;
    left: 0;
    bottom: -1px;
    opacity: 1;
    transition: 0.3s;
    pointer-events: none;
}
.plan_nav a span:hover:after{
    opacity: 0;
    bottom: -5px;
}

.plan_nav a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -12px;
    background-image: linear-gradient( 89deg, rgb(75,223,244) 0%, rgb(91,194,238) 79%);
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
}

.plan_nav a:hover:after {
    opacity: 1;
    bottom: -3px;
}
.plan_nav a.act:after{
    opacity: 0;
}
.plan_nav a.act span:after{
    opacity: 0;
}
.plan_nav a.act{
    color: #5ac4ee;
}
.plpr_img{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 275px;
}
.plpr_img img{
    max-width: 100%;
    max-height: 275px;
}

.plpr_info {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 60px;
    font-family: 'AGReverenceC';
}
.plpr_info:before{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #f5f5f6;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
}
.plpr_title{
    display: block;
    font-size: 24px;
    line-height: 1;
    color: #69c8ef;
    max-width: 200px;
    text-transform: uppercase;
    text-decoration: underline;
}
.plan_slid  .slick-slide {
    padding-left: 15px;
    box-sizing: border-box;
}
.plan_slid {
    margin-left: -15px;
}
.area{
    display: block;
}
.area span{
    display: block;
    color: #c9cacd;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
}
.area b{
    display: block;
    font-size: 30px;
    line-height: 1;
    color: #3b4251;
    margin-top: 8px;
    font-weight: normal;
}
.btn_style{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 18px;
    font-family: 'AGLettericaCondensedLight-Roman';
    line-height: 1;
    color: #3b4251;
    margin: 0 auto;
    border:none;
    background: none;
    margin-top: 36px;
    text-decoration: none;
    padding-left: 22px;
    cursor: pointer;
}
.btn_style span{
    position: relative;
    z-index: 2;
}
.btn_style l{
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #8ee2f6;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: 0.6s;
}
.btn_style l:before{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #8ee2f6;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.btn_style l:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #8ee2f6;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.plpr_in:hover .btn_style l{
    left: 10px;
}
.plpr_in:hover .btn_style l:before{
    animation: 2s ease-in-out 2 pulse;
}
.plpr_in:hover .btn_style l::after{
    animation: 2s ease-in-out .5s 2 pulse;
}


.btn_style:hover l{
    left: 10px;
}
.btn_style:hover l:before{
    animation: 2s ease-in-out 2 pulse;
}
.btn_style:hover l::after{
    animation: 2s ease-in-out .5s 2 pulse;
}

@keyframes pulse {
    0%{
        opacity: 0;
        height: 100%;
        width: 100%;
    }
    30%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        width: 170%;
        height: 170%;
    }
}
.btn_style:after{
    display: block;
    position: absolute;
    content: '';
    width: 27px;
    height: 9px;
    background: url(../images/slid_next_ar.svg)no-repeat center center;
    background-size: 100%;
    right: -35px;
    top: 50%;
    opacity: 1;
    transform: translateY(-50%);
    transition: 1s;
}
.plpr_in:hover .btn_style:after{
    width: 34px;
    height: 12px;
    right: -60px;
    opacity: 0;
}
.btn_style:hover:after{
    width: 34px;
    height: 12px;
    right: -60px;
    opacity: 0;
}
.plan_slid{
     margin-top: 54px;
}
.plpr_in{
    box-sizing: border-box;
    border: 1px solid #f5f5f6;
    padding: 30px 34px 44px 34px;
    transition: .5s;
    cursor: pointer;
}
.plan_slid .slick-list .slick-active {
    transition: .5s;
}
.plan_slid  .slick-list {
    padding-bottom: 50px;
}
.plan_slid .slick-list .slick-active+.slick-active+.slick-active+.slick-active+.slick-active {
    opacity: .4!important;
}
.plpr_in:hover {
    box-shadow: 21px 25px 38px 0px rgba(200, 225, 241, 0.3);
    -webkit-transform: scale(1.000001);
    transform: scale(1.000001);
}
.slid_nav{
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
}
.slid_nav .slid_nav_el{
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;
}
.slid_nav_el span{
    display: block;
    position: relative;
    width: 71px;
    height: 24px;
    transition: 0.8s;
    z-index: 2;
}
.slid_nav_l span{
    background: url(../images/slid_prev_ar.svg)no-repeat center top;
    background-size: 100%;
    opacity: 0.2;
}
.slid_nav_r span{
    background: url(../images/slid_next_ar.svg)no-repeat center top;
    background-size: 100%;
    opacity: 0.2;
}
.slid_nav_r{
    margin-left: 26px;
}
.slid_nav_l:hover span, .slid_nav_r:hover span{
    opacity: 1;
}
.slid_nav_l:before{
    display: block;
    position: absolute;
    content: '';
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #92daf5;
    transform-origin: center center;
    transform: scale(0,0);
    opacity: 0;
    top: -6px;
    right: -14px;
    z-index: 1;
    transition: .8s;
}
.slid_nav_l:hover:before{
    transform: scale(1,1);
    opacity: 1;
}
.slid_nav_r:before{
    display: block;
    position: absolute;
    content: '';
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #92daf5;
    transform-origin: center center;
    transform: scale(0,0);
    opacity: 0;
    top: -6px;
    left: -14px;
    z-index: 1;
    transition: .8s;
}
.slid_nav_r:hover:before{
    transform: scale(1,1);
    opacity: 1;
}
/* plan_price */



/* get_select */
.get_select{
    display: block;
    position: relative;
    padding-top: 314px;
    padding-bottom: 82px;
    background: url(../images/get_select_bg.jpg)no-repeat center top;
    background-size: cover;
    background-attachment: fixed;
}
.get_select_dec_bg{
    top: -3px;
    z-index: 3;
}
.get_select .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateX(-50%);
    top: -42px;
    left: 50%;
}

.tm_subtit{
    display: block;
    font-size: 30px;
    line-height: 1.2;
    color: #56caf0;
    text-transform: uppercase;
}

.gs_param{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-top: 112px;
}
.gs_nav{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

}


.gs_nav a{
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #3b4251;
    padding: 0 8px;
    font-size: 18px;
    line-height: 1;
}
.gs_nav a+a{
    margin-left: 20px;
}

.gs_nav a span{
    display: block;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.gs_nav a span:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #384251;
    left: 0;
    bottom: -1px;
    opacity: 1;
    transition: 0.3s;
    pointer-events: none;
}
.gs_nav a span:hover:after{
    opacity: 0;
    bottom: -5px;
}

.gs_nav a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -12px;
    background-image: linear-gradient( 89deg, rgb(75,223,244) 0%, rgb(91,194,238) 79%);
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
}

.gs_nav a:hover:after {
    opacity: 1;
    bottom: -3px;
}
.gs_nav a.act:after{
    opacity: 0;
}
.gs_nav a.act span:after{
    opacity: 0;
}
.gs_nav a.act{
    color: #5ac4ee;
}





.gs_floor_slid{
    display: block;
}
.gs_floor_slid .gs_txt{
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    margin-right: 54px;
    color: #3b4251;
}
.gs_floor_slid .slid_style_react.MuiSlider-root{
    display: inline-block;
    vertical-align: top;
    width: 730px;
}
.slid_style_react.MuiSlider-root {
    padding: 0;
    margin-top: 15px;
    height: 1px;
    color: #D3D3D3;
}
.gf_param .slid_style_react.MuiSlider-root{
    margin-top: 9px;
}

.gf_param .gfp_in:nth-child(2){
    margin-top: 12px;
}
.slid_style_react .MuiSlider-rail{
    height: 1px;
    background: rgba(255,255,255, 1);
    opacity: 1;
}
.slid_style_react .MuiSlider-valueLabel{
    font-size: 18px;
    top: -26px;
    left: auto;
}
.slid_style_react .MuiSlider-valueLabel > span{
    width: 32px;
    height: auto;
    line-height: 100%;
    transform: none;
    border-radius: 0px;
    background-color: initial;
}
.slid_style_react .MuiSlider-valueLabel > span > span{
    transform: none;
    color: #3b4251;
    font-size: 22px;
    line-height: 1;
    font-family: 'AGLettericaCondensedLight-Roman'!important;
}
.slid_style_react .MuiSlider-thumb.MuiSlider-active{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb.Mui-focusVisible, .slid_style_react .MuiSlider-thumb:hover{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb{
    width: 18px;
    height: 18px;
    background: #56cbf0;
    box-sizing: border-box;
    /* border: 2px solid #e2001a; */
    margin-top: -9px;
}
.slid_style_react .MuiSlider-track {
    height: 1px;
    color: #56cbf0;
}







.get_select .form_main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 70px;
}
.get_select .form_main .react_input_style {
    width: 32%;
}

.get_select .react_input_style + .react_input_style{
    margin-top: 0;
}


.MuiFormControl-root{
    width: 100%;
}
.MuiFormLabel-root{
    color: #6b747c;
    font-size: 16px;
}
.react_input_style .MuiInputLabel-root{
    color: #6b747c;
    top: 8px;
    font-family: 'AGLettericaCondensedLight-Roman'!important;
    font-size: 20px;
}
.react_input_style .MuiFormLabel-root.Mui-focused{
    color: #6b747c;
}

.react_input_style.react_textarea .MuiInputBase-input{
    height: 60px!important;
    width: 100%!important;
    line-height: 60px;
}
.MuiInputBase-multiline{padding: 0!important;}
.react_input_style .MuiInputBase-input{
    color: #6b747c;
    box-sizing: border-box;
    padding: 0;
    padding-top: 14px;
    padding-left: 42px;
    height: 60px;
    font-family: 'AGLettericaCondensedLight-Roman'!important;
}
.MuiInput-underline:after{
    display: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #b6d6e0!important;
}
.MuiInput-underline:before{
    border-bottom: 1px solid  #b6d6e0!important;
}
.MuiFormLabel-filled ~ .MuiInput-underline:before{
    border-bottom: 1px solid  #b6d6e0;
}
.MuiInputLabel-formControl{
    left: 42px!important;
    line-height: 0!important;
}
label + .MuiInput-formControl{
    margin-top: 0!important;
}

.input_name{
    background:url(../images/input_name.png)no-repeat center left;
}
.input_email{
    background: url(../images/input_email.png)no-repeat center left;
}
.input_phone{
    background:url(../images/input_phone.png)no-repeat center left;
}
.input_srok.react_select_style{
    background:url(../images/input_srok.png)no-repeat center left;
}
.input_vznos.react_select_style{
    background:url(../images/input_vznos.png)no-repeat center left;
}
.input_quest{
    background:url(../images/input_quest.png)no-repeat center left;
}

.get_select .form_main .btn_style{
    margin-top: 82px;
}

.get_select .form_main .btn_style span{
    font-size: 24px;
}
.get_select .form_main .btn_style l{
    width: 60px;
    height: 60px;
    top: 40%;
}
.get_select .btn_style:after{
    display: block;
    position: absolute;
    content: '';
    width: 40px;
    height: 14px;
    background: url(../images/slid_next_ar.svg)no-repeat center center;
    background-size: 100%;
    right: -48px;
    top: 62%;
    opacity: 1;
    transform: translateY(-50%);
    transition: 1s;
}
.get_select .btn_style:hover:after{
    width: 52px;
    height: 18px;
    right: -90px;
    opacity: 0;
}

/* get_select */

/* galery */
.galery{
    display: block;
    position: relative;
}
.galery .tm:before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 50%;
    width: 1px;
    height: 1px;
    left: 9%;
    bottom: 0;
    box-shadow: 0px 0px 74px 446px rgb(0 0 0 / 30%);
    transform: scale(2,1);
}
.gal_wrap{
    display: block;
    position: relative;
    overflow: hidden;
}
.gal_wrap .cw_main{
    position: absolute;
    left: 50%;
    top: 130px;
    transform: translateX(-50%);
    width: 1374px;
    pointer-events: none;
    z-index: 4;
}
.gal_wrap .cw_main .tm{
    text-align: left;
}
.gal_wrap .cw_main .tm span{
    color: #fff;
}
.gal_wrap .cw_main .tm span:after{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateY(-50%);
    top: 50%;
    left: -84px;
    z-index: 2;
}
.gl_in{
    display: block;
}
.gl_in img{
    display: block;
    width: 100%;
}
.galery .slick-initialized .slick-slide{
    font-size: 0;
}
.galery .slid_nav {
    display: flex;
    width: 714px;
    background: #8ce6f7;
    padding: 52px 66px 50px 76px;
    box-sizing: border-box;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.galery .slid_nav_l span{
    background: url(../images/slid_prev_w_ar.svg)no-repeat center top;
    background-size: 100%;
    opacity: 0.4;
}
.galery .slid_nav_r span{
    background: url(../images/slid_next_w_ar.svg)no-repeat center top;
    background-size: 100%;
    opacity: 0.4;
}
.galery .slid_nav_r{
    margin-left: 26px;
}
.galery .slid_nav_l:hover span, .galery .slid_nav_r:hover span{
    opacity: 1;
}
.galery .slid_nav_l:before,.galery .slid_nav_r:before{
    display: none;
}
.galery .slid_counter{
    font-family: 'AGReverenceC';
}
.galery .slid_counter b{
    font-size: 36px;
    color: #fff;
    font-weight: normal;
}
.galery .slid_counter span{
    font-size: 20px;
    color: #64b6d9;
}
.slid_wrap{
    position: absolute;
    left: 50%;
    top: auto;
    bottom: -48px;
    transform: translateX(-50%);
    width: 1374px;
    z-index: 5;
}
/* galery */



/* how_buy */
.how_buy{
    display: block;
    padding-top: 268px;
    padding-bottom: 210px;
    background: url(../images/how_buy_bg.jpg)no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
}
.how_buy .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateX(-50%);
    top: -42px;
    left: 50%;
}
.hb_list {
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1030px;
    margin: 0 auto;
    margin-top: 96px;
}
.hb_el {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    align-content: flex-start;
    cursor: pointer;
}
.hb_el > span{
    display: block;
    font-size: 34px;
    line-height: 1;
    color: #343a47;
    font-family: 'AGReverenceC';
    margin-top: 18px;
}

.hb_el .btn_style{
    margin-left: 0;
}



/* how_buy */



/* parking */
.parking{
    display: block;
    position: relative;
    background: url(../images/parking_bg.jpg)no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 300px;
    padding-bottom: 282px;
    text-align: center;
}
.parking .tm{
    color: #fff;
}
.parking .tm span{
    display: block;
    font-size: 150px;
    line-height: .8;
}
.parking .tm .tm_subtit{
    color: #fff;
    font-size: 44px;
    line-height: 1;
}
.parking p{
    display: block;
    font-size: 32px;
    line-height: 1;
    color: #56caf0;
    text-align: center;
    margin-top: 18px;
    font-family: 'AGLettericaCondensedLight-Roman', sans-serif;
    font-weight: normal;
}
.parking .btn_style{
    margin-top: 100px;
    color: #fff;
}
.parking .btn_style l{
    width: 55px;
    height: 55px;
}
.parking .btn_style span{
    font-size: 24px;
}
.parking .btn_style:after{
    display: block;
    position: absolute;
    content: '';
    width: 40px;
    height: 14px;
    background: url(../images/slid_next_w_ar.svg)no-repeat center center;
    background-size: 100%;
    right: -48px;
    top: 62%;
    opacity: 1;
    transform: translateY(-50%);
    transition: 1s;
}
.parking .btn_style:hover:after{
    width: 52px;
    height: 18px;
    right: -90px;
    opacity: 0;
}
.parking_dec_bg{
    bottom: -55px;
    left: 0;
}
/* parking */


/* about */
.about{
    display: block;
    padding-top: 160px;
}
.about .cw_main {
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: stretch;
}
.abt_l{
    display: block;
    max-width: 600px;
    padding-top: 50px;
}
.abt_l .tm{
    text-align: left;
}
.abt_l .tm .tm_subtit{
    margin-top: 18px;
    font-size: 22px;
}
.abt_l .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateY(-50%);
    top: 50%;
    left: -84px;
    z-index: 2;
}

.abt_l p{
    display: block;
    margin-top: 80px;
    font-size: 22px;
    line-height: 1.2;
}
.abt_l p+p{
    margin-top: 24px;
}
.abt_l p a{
    color: #1f90c5;
    text-decoration: underline #1f90c5;
}
.abt_l p a:hover{
    text-decoration: none;
}
.abt_r {
    display: block;
    position: relative;
    width: 654px;
}
.abt_r img{
    display: block;
    width: 100%;
}

.prj_doc_btn{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    text-decoration: none;
    align-items: flex-start;
    position: absolute;
    background: #93d9f4;
    width: 176px;
    height: 176px;
    top: -27px;
    left: -94px;
    padding: 42px 22px 10px 22px;
    box-shadow:none;
    box-sizing: border-box;
    transition: .6s;
}
.prj_doc_btn:hover{
    background: #49a6ce;
    box-shadow: 21px 25px 38px 0px rgba(0, 0, 0, 0.3);
}
.prj_doc_btn l{
    display: block;
    position: absolute;
    background:#fff url(../images/doc_ar.svg)no-repeat center center;
    background-size: 11px 14px;
    width: 41px;
    height: 41px;
    bottom: 0;
    right: 0;
    z-index: 3;
    border-radius:0%;
    transform-origin: center center;
    transition: .4s;
}
.prj_doc_btn l:before{
    display: block;
    position: absolute;
    content: '';
    width: 98%;
    height: 98%;
    border-radius: 50%;
    border: 1px solid #fff;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.prj_doc_btn l:after{
    display: block;
    position: absolute;
    content: '';
    width: 98%;
    height: 98%;
    border-radius: 50%;
    border: 1px solid #fff;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.prj_doc_btn:hover l:before{
    animation: 2s ease-in-out infinite pulse;
}
.prj_doc_btn:hover l::after{
    animation: 2s ease-in-out .5s infinite pulse;
}
.prj_doc_btn:hover l{
    transform: scale(1.2);
    border-radius:50%;
    bottom: -7px;
    right: -7px;
}

h5{
    color: #fff;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1.1;
}
.prj_doc_btn span{
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    color: #49a6ce;
    transition: 0.6s;
}
.prj_doc_btn:hover span{
    color: #fff;
}
/* about */

/* hod_str */
.hod_str{
    display: block;
    position: relative;
    padding-top: 280px;
    background: url(../images/hod_bg.jpg)no-repeat center top;
    background-size: cover;
    padding-bottom: 50px;
}
.hod_str .tm span:before {
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateX(-50%);
    top: -42px;
    left: 50%;
}
.hod_str .cw_main {
    margin-top: 34px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
}
.hod_str .cw_main a{
    text-decoration: none;
}
.year_list {
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 36px;
}
.year_list a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 26px;
    line-height: 1;
    color: rgba(60, 63, 71, .45);
    min-width: 70px;
    font-family: 'AGReverenceC';
    text-align: center;
    transition: .3s;
}
.year_list a span{
    display: block;
    position: relative;
    z-index: 2;
}
.year_list a:hover{
    color: rgba(60, 63, 71, 1);
}

.year_list a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -6px;
    background-image: linear-gradient( 89deg, rgb(75,223,244) 0%, rgb(91,194,238) 79%);
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
    pointer-events: none;
}

.year_list a:hover:after {
    opacity: 1;
    bottom: 2px;
}
.year_list a+a{
    margin-left: 5px;
}
.year_list a.act{
    font-size: 36px;
    color: rgba(60, 63, 71, 1);
}
.year_list a.act:after {
    opacity: 1;
    bottom: 2px;
}


.month_list{
    display: block;
    position: relative;
}
.month_list a{
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0 4px;
}
.month_list a+a{
    margin-left: 24px;
}
.month_list a span{
    position: relative;
    z-index: 4;
}


.month_list a span:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #384251;
    left: 0;
    bottom: -1px;
    opacity: 1;
    transition: 0.3s;
    pointer-events: none;
}
.month_list a:hover span:after{
    opacity: 0;
    bottom: -5px;
}

.month_list a:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    left: 0;
    bottom: -8px;
    background-image: linear-gradient( 89deg, rgb(75,223,244) 0%, rgb(91,194,238) 79%);
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
}

.month_list a:hover:after {
    opacity: 1;
    bottom: -3px;
}
.month_list a.act:after{
    opacity: 0;
}
.month_list a.act span:after{
    opacity: 0;
}
.month_list a.act{
    color: #5ac4ee;
}

.hod_slid_wrap{
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    margin-top: 48px;
}
.hod_in{
    display: block;
    position: relative;
    box-sizing: border-box;
    border-right: 1px solid #fff;
}
.hod_in img{
    display: block;
    height: 400px;
}
.hod_slid_wrap .slid_nav{
    display: block;
    text-align: center;
    margin-top: 50px;
}
/* hod_str */

/* contacts */
.contacts{
    display: block;
    position: relative;
    background: url(../images/cont_bg.jpg)no-repeat center top;
    background-size: cover;
    padding-top: 290px;
    padding-bottom: 250px;
}
.contacts .cw_main {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 168px;
    box-sizing: border-box;
}
.contacts .tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateY(-50%);
    top: 50%;
    left: -84px;
    z-index: 2;
}
.cont_info{
    display: block;
    max-width: 500px;
}
.cont_addr span, .cont_phone span{
    display: block;
    font-size: 22px;
    line-height: 1.1;
    color: #b6c7cd;
    font-family: 'AGReverenceC';
}
.cont_addr{
    display: block;
    margin-top: 48px;
}
.cont_addr p{
    display: block;
    line-height: 1;
    font-size: 22px;
    margin-top: 2px;
}
.cont_phone {
    display: block;
    margin-top: 40px;
}
.cont_phone a{
    display: inline-block;
    vertical-align: top;
    color: #7ec5e4;
    font-size: 28px;
    line-height: 1;
    text-decoration: none;
    margin-top: 2px;
}
.quest{
    display: block;
    margin-top: 74px;
}
h6{
    display: block;
    font-size: 40px;
    line-height: 1;
    font-family: 'AGReverenceC';
    font-weight: normal;
}
.quest .btn_style{
    font-size: 20px;
}
.cont_map{
    display: block;
    position: relative;
    width: 500px;
    height: 450px;
}
#map{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}
/* contacts */

/* footer */
.footer{
    display: block;
    position: absolute;
    border-top: 1px solid #fff;
    width: 100%;
    left: 0;
    bottom: 0;
}
.footer .cw_main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 32px;
    padding-top: 36px;
}

.f_l span{
    opacity: 0.45;
    cursor:default;
}
.politic{
    display: block;
}
.politic a{
    display: block;
    position: relative;
    text-decoration: none;
    color: #444b59;
    transition: .4s;
    opacity: 0.45;
}
.politic a:hover{
    opacity: 1;
}
.politic a:before{
    display: block;
    position: absolute;
    content: '';
    width: 70px;
    height: 2px;
    background: #5bc2ee;
    transition: .3s;
    pointer-events: none;
    left: 0;
    top: -40px;
    opacity: 0;
}
.politic a:hover:before{
    opacity: 1;
    top: -43px;
}
.f_r span{
    display: inline-block;
    vertical-align: top;
    line-height: 28px;
    opacity: 0.45;
}
.f_r a{
    display: inline-block;
    vertical-align: top;
    opacity: 0.45;
    margin-left: 18px;
    transition: .4s;
}
.f_r a:hover{
    opacity: 1;
}
/* footer */







/*popup_rgba*/
@media screen and (min-width: 968px){
    .popup_rgba {
        display: none;
    }
}
.popup_rgba {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999999;
    /* background: url("../img/popup_rgba.png"); */
    background: rgba(6, 31, 35, .7);
    overflow: hidden;
    -ms-overflow-y: scroll;
        overflow-y: scroll;
}

    .popup_cell {
        display: table-cell;
        vertical-align: middle;
    }
    .popup_table {
        position: absolute;
        display: table;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .closeform {
    display: block;
    position: absolute;
    -webkit-background-size: cover;
    background-size: cover;
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
    z-index: 999999;
}
    .closeform:hover{opacity:  0.5;}









   .popup_main {
        position: relative;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 630px;
        margin-bottom: 70px;
        background: #FFF;
        box-sizing: border-box;
        padding: 140px 50px 88px 50px;
    }
    .pu_dec{
        display: block;
        position: absolute;
        top: 0;
        left: 0;

    }
    .pu_tm{
        display: inline-block;
        position: relative;
        font-family: 'AGReverenceC';
        text-align: center;
        width: 100%;
    }
    .pu_tm span{
        display: block;
        position: relative;
        text-transform: uppercase;
        font-size: 60px;
        line-height: 1;
        z-index: 2;
    }
    .pu_tm span:before{
        display: block;
        position: absolute;
        content: '';
        width: 57px;
        height: 3px;
        background: url(../images/dec_img.jpg)no-repeat center top;
        background-size: cover;
        transform: translateX(-50%);
        top: -42px;
        left: 50%;
    }
    .pu_subtit{
        color: #56caf0;
        display: block;
        text-transform: uppercase;
        font-size: 30px;
    }
    .pu_form{
        display: block;
        margin: 0 auto;
        width: 434px;
        margin-top: 70px;
    }
    .pu_form .btn_style{
        margin-top: 30px;
        font-size: 24px;
    }
    .pu_form .react_input_style + .react_input_style{
        margin-top: 13px;
    }









.react_select_style{
    /* margin-top: 16px; */
    margin-bottom: 26px;
    width: 100%;
}
.react_select_style.MuiInput-underline:after{
    display: none;
}
.react_select_style.MuiInput-underline:before{
    border-bottom: 1px solid  #b6d6e0;
}
.react_select_style.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #b6d6e0;
}
.react_select_style .MuiNativeSelect-select{
    background: none;
    padding: 0;
    padding-right: 24px;
    padding-left: 42px;
    height: 60px;
}
.react_select_style.MuiInputBase-root{
    font-family: 'AGLettericaCondensedLight-Roman'!important;
    color: #6b747c;
    font-size: 20px;
}
.react_select_style .MuiNativeSelect-select:not([multiple]) option, .react_select_style .MuiNativeSelect-select:not([multiple]) optgroup{
    color: #000;
}
.react_select_style .MuiNativeSelect-select:focus{
    background: none;
}
.react_select_style .MuiNativeSelect-icon path{
    display: none;
}
.react_select_style .MuiNativeSelect-icon{
    background: url(../images/select_ar.svg)no-repeat center center;
    background-size: 14px 8px;
    opacity: 1;
    transform: scale(1,1);
    transition: .2s;
}
.react_select_style .MuiNativeSelect-select:focus ~ .MuiNativeSelect-icon{
    opacity: .7;
    transform: scale(-1,-1);
    transition: .2s;
}

.pu_doc{
    max-width: 970px;
    padding-left: 70px;
    padding-right: 70px;
}
.doc_list {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
}
.doc_el{
    width: 48%;
}
.doc_el a{
    display: block;
    font-size: 22px;
    line-height: 1;
    color: #57caf0;
    text-decoration: underline #57caf0;
}
.doc_el a+a{
    margin-top: 12px;
}
.doc_el a:hover{
    text-decoration: none;
}

.pu_flat{
    max-width: 1234px;
    padding-left: 118px;
    padding-right: 118px;
}
.pu_flat .pu_tm{
    text-align: left;
}
.pu_flat .pu_tm span:before{
    display: block;
    position: absolute;
    content: '';
    width: 57px;
    height: 3px;
    background: url(../images/dec_img.jpg)no-repeat center top;
    background-size: cover;
    transform: translateY(-49%);
    top: 50%;
    left: -80px;
}
.flat_area{
    display: block;
    margin-top: 12px;
    font-size: 24px;
    line-height: 1;
    font-style: italic;
    color: #afb4b7;
}
.flat_area span{
    display: inline-block;
    vertical-align: top;
}
.flat_area span + span{
    margin-left: 64px;
}
.flat_area span b{
    display: inline-block;
    font-weight: normal;
    color: #3b4251;
    margin-left: 8px;
}
.flat_area span b sup{
    display: inline-block;
    margin-left: 2px;
    font-size: 14px;
    line-height: 0;
}
.pu_flat .pu_form{
    margin-top: 92px;
    float: left;
}
.pu_flat .form_tit{
    display: block;
    font-size: 40px;
    color: #56caf0;
    line-height: 1;
    text-transform: uppercase;
    font-family: 'AGReverenceC';
    margin-bottom: 36px;
}
.flat_img{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 450px;
    height: 416px;
    float: right;
    margin-top: 28px;
}
.flat_img img{
    display: block;
    max-width: 100%;
    max-height: 416px;
}

.pu_action {
    position: fixed;
    width: 570px;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    z-index: 500;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,.2);
    /*transition: 1s;*/
}

.pu_action__open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pu_action .pu_form {
    display: block;
    width: auto;
    min-width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    margin-top: 20px;
}

.pu_action__img {
    display: block;
    width: 100%;
}

.pu_action__btn {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%) translateY(-50%);
    top: 50%;
    transition: 1s;
}

.pu_action__open .pu_action__btn {
    top: 0;
    transform: translateX(100%) translateY(0%);
}

    .pu_action__btn img {
        display: block;
    }

    .pu_action__close {
        position: absolute;
        cursor: pointer;
        width: 46px;
        height: 46px;
        right: 10px;
        top: 10px;
        z-index: 20;
        background: #3ce9f9;
    }

        .pu_action__close:before, .pu_action__close:after {
            content: "";
            display: block;
            position: absolute;
            width: 3px;
            height: 40px;
            left: 50%;
            margin-left: -1px;
            background: #fff;
            transform-origin: center center;
            transform: rotate(45deg);
            top: 50%;
            margin-top: -20px;
        }

        .pu_action__close:after {
            transform: rotate(-45deg);
        }

    /*popup_rgba*/

    .marquiz__button_fixed-left {
        top: auto !important;
        bottom: -20px !important;
        transform: rotate(-90deg) translate(0) !important;
    }
