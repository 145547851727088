
/*@media*/
@media screen and (max-height:  860px) {
    .react_select_style{
        margin-bottom: 12px;
    }
    .pu_form .react_input_style + .react_input_style {
        margin-top: 12px;
    }
    .pu_form .btn_style {
        margin-top: 40px;
    }
    .pu_form{
        margin-top: 30px;
    }
    .popup_main {
        padding: 90px 50px 48px 50px;
    }
    .pu_flat .pu_tm span:before {
        width: 30px;
        left: -40px;
    }
}
@media screen and (max-height:  680px) {
}
@media screen and (max-height:  600px) {
}
@media screen and (max-width: 1850px){
    .parking_dec_bg {
        bottom: -20px;
    }
}
@media screen and (max-width: 1750px){
    .gal_wrap .cw_main{
        width: 100%;
        box-sizing: border-box;
    }
    .gal_wrap .cw_main .tm span:after{
        left: 0;
    }
    .gal_wrap .cw_main .tm span{
        padding-left: 80px;
    }
}
@media screen and (max-width: 1550px){    
    .abt_l {
        padding-left: 92px;
        box-sizing: border-box;
    }
    .cw_main{
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
    .plan_slid .slick-list .slick-active+.slick-active+.slick-active+.slick-active{
        opacity: .4!important;
    }
    .cont_info{
        padding-left: 80px;
    }
}
@media screen and (max-width: 1500px) {
    .elipses {
        /* width: 85vw; */
        left: -35%;
        top: -41%;
    }
    .loc .tm span:before {
        display: block;
        position: absolute;
        content: '';
        width: 40px;
        height: 3px;
        top: 50%;
        left: 0;
    }
    .loc .tm span {
        padding-left: 56px;
    }
}
@media screen and (max-width: 1410px) {
    .abt_r {
        width: 600px;
    }
    .abt_l p{
        font-size: 18px;
        margin-top: 40px;
    }
    .prj_doc_btn{
        left: -32px;
    }
    
    .gal_wrap .cw_main{
        top: 70px;
    }
    .galery .slid_nav {
        width: 500px;
        padding: 20px;
    }
    .slid_wrap{
        width: 100%;
    }
    .elipses {
        left: -16%;
        top: -47%;
        width: 1034px;
    }
    .top_btn{
        width: 56px;
    }
    .loc{
        padding-top: 250px;
        padding-bottom: 234px;
    }
    .loc_img img {
        max-width: 24px;
        max-height: 24px;
    }
    .loc_img:before{
        width: 100%;
        height: 100%;
    }
    .loc_img:after{
        width: 100%;
        height: 100%;
    }
    .loc_img {
        width: 40px;
        height: 40px;
    }
    .loc .tm {
        font-size: 54px;
    }
    .loc_el4 {
        left: 76.5%;
        top: 56%;
    }
    .loc_el5 {
        left: 82.5%;
        top: 67%;
    }
    .loc_el span{
        font-size: 20px;
    }
    
    
}
@media screen and (max-width: 1360px) {
    .gs_floor_slid .slid_style_react.MuiSlider-root{
        width: 530px;
    }
    .get_select .form_main .react_input_style {
        width: 30%;
    }
    .abt_l {
        padding-left: 48px;
    }
    .abt_l .tm span:before {
        width: 37px;
        left: -50px;
    }
}
@media screen and (max-width: 1250px){
    .advant_list .adv_el:nth-child(2) {
        width: 360px;
    }
    .pu_flat {
        max-width: 1034px;
    }
    .flat_img {
        width: 330px;
        height: 346px;
        margin-top: -12px;
    }
    .loc{
        background-attachment: scroll;
    }
}
@media screen and (max-width: 1150px){
    *{
        background-attachment: scroll!important;
    }
    .popup_main{
        width: 300px;
        padding: 50px 10px 28px 10px;
    }
    .pu_dec{
        max-width: 100%;
    }
    .pu_tm span{
        font-size: 40px;
    }
    .pu_tm span:before{
        top: -22px;
    }
    .doc_list{
        margin-top: 30px;
        flex-direction: column;
    }
    .doc_el {
        width: 100%;
    }
    .doc_el+.doc_el{
        margin-top: 12px;
    }
    .doc_el a{
        font-size: 16px;
    }
    .pu_form{
        width: 100%;
    }
    .pu_subtit {
        font-size: 16px;
    }
    .pu_flat .pu_tm span:before{
        display: none;
    }
    .flat_img {
        width: 100%;
        height: 346px;
        margin-top: 20px;
        float: none;
    }
    .pu_flat .pu_form {
        margin-top: 20px;
        float: none;
    }
    .flat_img img{
        max-height: 100%;
    }
    .flat_area{
        font-size: 20px;
    }
    .flat_area span{
        display: block;
    }
    .flat_area span + span{
        margin: 0 auto;
        margin-top: 12px;
    }
    .pu_flat .form_tit{
        font-size: 26px;
    }
    .footer .cw_main{
        flex-direction: column;
        padding: 12px 0px;
    }
    .politic {
        margin: 8px 0px;
    }
    .politic a:hover:before{
        display: none;
    }
    .cont_map {
        width: 300px;
        height: 250px;
        margin: 0 auto;
        margin-top: 50px;
    }
    .cont_addr p{
        font-size: 18px;
    }
    .quest {
        margin-top: 34px;
    }
    .contacts {
        padding-top: 50px;
        padding-bottom: 230px;
        background: url(../images/cont_bg_m.jpg)no-repeat center center;
        background-size: cover;
    }
    .contacts .cw_main{
        flex-direction: column;
        padding-right: 10px;
    }
    .cont_info {
        padding-left: 0;
        margin: 0 auto;
        max-width: 300px;
    }
    .contacts .tm span:before{
        display: none;
    }
    .abt_l .tm{
        font-size: 40px;
    }
    .abt_l .tm .tm_subtit{
        font-size: 16px;
    }
    .abt_l p{
        font-size: 17px;
    }
    .abt_r {
        width: 300px;
        margin: 0 auto;
        margin-top: 50px;
    }
    .prj_doc_btn {
        left: -6px;
        top: -20px;
        width: 126px;
        height: 126px;
        padding: 12px;
        background: #49a6ce;
        box-shadow: 21px 25px 38px 0px rgba(0, 0, 0, 0.3);
    }
    h5 {
        font-size: 14px;
    }
    .prj_doc_btn span{
        font-size: 11px;
    }
    .prj_doc_btn l{
        transform: scale(1.2);
        border-radius:50%;
        bottom: -7px;
        right: -7px;
    }
    .prj_doc_btn span{
        color: #fff;
    }
    .prj_doc_btn l:before{
        animation: 2s ease-in-out infinite pulse;
    }
    .prj_doc_btn l::after{
        animation: 2s ease-in-out .5s infinite pulse;
    }
    .about{
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .abt_l{
        max-width: 300px;
        margin: 0 auto;
        padding: 0;
    }
    .about .cw_main{
        flex-direction: column;
    }
    .parking{
        padding-top: 100px;
        padding-bottom: 140px;
    }
    .how_buy {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .hb_list{
        max-width: 200px;
        flex-direction: column;
        margin-top: 50px;
    }
    .hb_el{
        width: 100%;
    }
    .hb_el+.hb_el{
        margin-top: 80px;
    }
    .gal_wrap .cw_main {
        top: auto;
        left: 0;
        position: relative;
        padding-top: 50px;
        padding-bottom: 40px;
        text-align: center;
        transform: none;
    }
    .gal_wrap .cw_main .tm {        
        text-align: center;
    }
    .gal_wrap .cw_main .tm span:after{
        display: none;
    }
    .galery .slid_nav{
        width: 100%;
    }
    .slid_wrap {
        width: 100%;
        position: relative;
        top: 0;
        left: auto;
        transform: none;
        margin-top: 0;
        padding: 0;
    }
    .gal_wrap .cw_main .tm span{
        padding: 0;
        color: #3b4251;
    }
    .galery .tm:before{display: none;}
    .get_select {
        padding-top: 214px;
    }
    .gs_param {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-top: 42px;
        flex-direction: column;
    }
    .gs_floor_slid{
        margin-top: 40px;
    }
    .gs_floor_slid .gs_txt{
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
    }
    .gs_floor_slid .slid_style_react.MuiSlider-root{
        margin-top: 0;
    }
    .slid_style_react .MuiSlider-valueLabel > span > span{
        font-size: 16px;
    }
    .slid_style_react .MuiSlider-valueLabel {
        top: -12px;
    }
    .get_select .form_main{
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 40px;
    }
    .get_select .form_main .react_input_style{
        width: 100%;
    }
    .get_select .react_input_style + .react_input_style{
        margin-top: 14px;
    }
    .pl_wrap{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 50px;
        text-align: center;
        padding: 0;
    }
    .plpr_in{
        text-align: left;
    }
    .plan_price{
        padding-top: 100px;
    }
    .menu_btn{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid #8ee2f6;
        background: #8ee2f6;
        box-sizing: border-box;
    }
    .menu_btn span{
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1.1;
        font-family: 'AGReverenceC';
        color: #fff;
    }
    .top_menu_list{
        display: none;
    }
    .menu_list{
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }
    .menu_list a{
        display: block;
    }
    .menu_list a + a{
        margin-top: 12px;
    }
    .menu_list a span{
        text-transform: uppercase;
        font-family: 'AGReverenceC';
        font-size: 20px;
        line-height: 1;
        color: #fff;
    }
    logo{
        width: 120px;
    }
    .menu .cw_main{
        padding-top: 12px;
    }
    .menu_right a l:before {
        animation: 2s ease-in-out infinite pulse;
    }
    .menu_right a l:after {
        animation: 2s ease-in-out infinite pulse;
    }
    .header_dec_bg{
        bottom: -16px;
    }
    .advant_list{
        max-width: 320px;
        margin: 0 auto;
        flex-direction: column;
    }
    .advant_list .adv_el:nth-child(2){
        width: 100%;
        margin-top: 40px;
        margin-bottom: 50px;
    }
    .adv_img_bottom{
        margin-top: 30px;
    }
    .advant_list .adv_el:hover .adv_img_bottom {
        margin-top: 30px;
    }
    .advant_list .adv_el:hover .adv_img_bottom span {
        margin-top: 18px;
    }
    .advant_list .adv_el .adv_img_bottom span {
        margin-top: 18px;
    }
    .advant_list .adv_el:hover span:before{
        width: 42px;
        height: 42px;
        left: -19px;
    }
    .advant_list .adv_el:hover .adv_img_top span{
        margin-left: 34px;
    }
    .advant_list .adv_el{
        width: 100%;
    }
    .adv_el span{
        font-size: 21px;
    }
    .tm{
        font-size: 44px;
    }
    .loc .tm {
        font-size: 44px;
    }
    .advant_list {
        padding-top: 46px;
    }
    
    .top_btn img {
        width: 26px;
        height: 44px;
        bottom: -10px;
    }
    .top_btn {
        width: 56px;
        height: 56px;
        bottom: 5px;
        right: 5px;
        border-radius: 50%;
        opacity: 1;
        overflow: hidden;
    }
    .loc {
        padding-top: 70px;
        padding-bottom: 434px;
    }
    .loc .tm{
        text-align: center;
    }
    .elipses {
        left: 50%;
        top: -77%;
        width: 929px;
        transform: translateX(-50%);
    }
    .loc_el1 {
        left: 48.5%;
        top: 67%;
    }
    .loc_el2 {
        left: 34.5%;
        top: 62%;
    }
    .loc_el3 {
        left: 49.5%;
        top: 77%;
    }
    .loc_el4 {
        left: 35.5%;
        top: 84%;
    }
    .loc_el5 {
        left: 44.5%;
        top: 97%;
    }
    .loc_el2 {
    left: 34.5%;
    top: 62%;
    }
}
@media screen and (max-width: 850px){
    .parking .tm span{
        font-size: 76px;
    }
    .parking .tm .tm_subtit{
        font-size: 22px;
    }
    .parking p{
        font-size: 24px;
    }
    .parking .btn_style span {
        font-size: 20px;
    }
    .parking .btn_style {
        margin-top: 50px;
        left: -30px;
    }
}
@media screen and (max-height: 768px) and (max-width: 850px){
}
@media screen and (max-height:  780px) {
    .pu_action {
        width: 440px;
    }
    
    .pu_action .pu_form .react_input_style .MuiInputLabel-root {
        font-size: 16px;
    }

    .pu_action .react_input_style .MuiInputBase-input {
        height: 46px;
    }

    .pu_action .pu_form .MuiInputLabel-formControl {
        top: -4px;
    }
    .pu_action .pu_form .react_input_style+.react_input_style {
        margin-top: 19px;
    }
    .pu_action .pu_form .btn_style {
        margin-top: 32px;
    }
    .pu_action .pu_form {
        margin-top: 29px;
    }
}
@media screen and (max-width: 768px){
}

@media screen and (max-width: 730px){

}
@media screen and (max-width: 740px) {
}
@media screen and (max-width: 650px){
    .hd_inner{
        width: 300px;
        padding: 18px 22px;
    }
    h1{
        font-size: 46px;
    }
    .hd_info{
        font-size: 20px;
    }
    .menu_right a l{
        display: none;
    }
    logo {
        width: 80px;
    }
    .menu_btn{
        width: 50px;
        height: 50px;
    }
    .menu_btn span{
        font-size: 14px;
    }
    .menu_right a{
        font-size: 18px;
    }
    .header_dec_bg {
        bottom: -2px;
    }
}
@media screen and (max-width:  590px) {   
    .tm_subtit{
        font-size: 18px;
    }
    .get_select {
        padding-top: 148px;
    }
    .gs_floor_slid .slid_style_react.MuiSlider-root{
        width: 300px;
    }
    .get_select .form_main .btn_style {
        margin-left: 0;
        margin-top: 60px;
    }
    .get_select .form_main .btn_style span {
        font-size: 18px;
    }
    .gs_nav {
        flex-wrap: wrap;
        justify-content:center;
    }
    .gs_nav a{
        padding: 0 3px;
        margin: 4px;
    }
    .gs_nav a+a{
        margin-left: 0;
        margin: 4px;
    }
} 
@media screen and (max-width: 560px){
    .loc .tm span:before{
        display: none;
    }
    .loc .tm span{
        padding-left: 0;
    }
    .loc{
        background-position: center center;
    }
    /* .loc_el{
        position: relative;
        left: auto;
        top:auto;
    }
    .elipses{
        width: auto;
        height: auto;
        position: relative;
        transform: none;
        margin-top: 50px;
        padding: 0 10px;
        left: 0;
        top: 0;
    }
    .elipses > img{
        display: none;
    }
    .loc_el+.loc_el{
        margin-top: 20px;
    }
    .loc {
        padding-top: 70px;
        padding-bottom: 40px;
    } */
}

@media screen and (max-width: 640px){
    .pu_action .btn_style {
        margin-top: 20px;
    }
    .pu_action__btn img {
        width: 40px;
    }
    .pu_action {
        width: calc(100% - 50px);
    }
    .pu_form .btn_style {
        font-size: 16px;
        height: 44px;
        line-height: 44px;
    }
    .pu_action .pu_form {
        padding-bottom: 25px;
    }
}

@media screen and (max-width:  420px) {
}

@media screen and (max-width:  360px) {
}

/*@media*/